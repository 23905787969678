import { useDeviceContext } from 'context/device';

import styles from './currency-bar.module.scss';

import * as RadioGroup from '@radix-ui/react-radio-group';
import Image from 'comps/image';

export default function CurrencyBar({
  className,
  datas,
  value,
  onValueChange,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <RadioGroup.Root
      className={classNames.join(' ')}
      value={value}
      onValueChange={onValueChange}
      orientation="vertical"
      {...all}
    >
      {datas.map(data => {
        return (
          <RadioGroup.Item
            key={data.id}
            className={styles.item}
            value={data.id}
          >
            <span>{data.name}</span>
            <Image
              className={styles.image}
              src={data.iconSrc}
              alt={data.name}
              objectFit="contained"
            />
          </RadioGroup.Item>
        );
      })}
    </RadioGroup.Root>
  );
}
