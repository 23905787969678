import { useDeviceContext } from 'context/device';
import styles from './text-input.module.scss';

export default function TextInput({
  className,
  type,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <input
      className={classNames.join(' ')}
      type={type ?? 'text'}
      {...all}
    />
  );
}
