import { useDeviceContext } from 'context/device';

import styles from './amount-form.module.scss';

import * as RadioGroup from '@radix-ui/react-radio-group';
import * as Label from '@radix-ui/react-label';
import CtaButton from 'comps/cta-button';

const presetValues = [50, 100, 500, 1000, 2000, 5000, 8000];

export default function AmountForm({
  id,
  className,
  value,
  minValue,
  maxValue,
  hasPresetButtons,
  onValueChange,
  ...all
}) {
  value = Number(value);
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  const inputId = `${id}-input`;
  return (
    <form id={id} className={classNames.join(' ')} {...all}>
      <div className={styles['input-container']}>
        <Label.Root htmlFor={inputId}>Deposit amount</Label.Root>
        <input
          id={inputId}
          className="no-spin"
          type="number"
          value={value}
          min={minValue}
          max={maxValue}
          required
          onChange={e => onValueChange(e.target.value)}
        />
      </div>
      {hasPresetButtons && <RadioGroup.Root
        className={styles['amount-buttons']}
        value={value}
        orientation="horizontal"
        onValueChange={onValueChange}
      >
        {presetValues.map(v => {
          return (
            <RadioGroup.Item
              key={v}
              className={styles['amount-button']}
              value={v}
            >{v}</RadioGroup.Item>
          );
        })}
      </RadioGroup.Root>}
      <CtaButton
        className={styles['submit-button']}
        type="submit"
      >Continue</CtaButton>
    </form>
  );
}
