import React from 'react';
import { useDeviceContext } from 'context/device';

import styles from './content.module.scss';

import Image from 'comps/image';
import StatsBar from './stats-bar';
import LinkBar from './link-bar';

import bgImg from 'assets/images/affiliate/bg.jpg';
import speechImg from 'assets/images/affiliate/highlights/speech.png';
import speech2xImg from 'assets/images/affiliate/highlights/speech-2x.png';
import smileyImg from 'assets/images/affiliate/highlights/smiley.png';
import smiley2xImg from 'assets/images/affiliate/highlights/smiley-2x.png';
import starImg from 'assets/images/affiliate/highlights/star.png';
import star2xImg from 'assets/images/affiliate/highlights/star-2x.png';

const Content = React.forwardRef(({
  className,
  ...all
}, ref) => {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  const idPrefix = 'affiliate-page-content';
  const bgImageClipPathId = `${idPrefix}-bg-img-clip-path`;
  return (
    <div ref={ref} className={classNames.join(' ')} {...all}>
      <div className={styles.header}>
        <svg width="0" height="0">
          <defs>
            <clipPath id={bgImageClipPathId} clipPathUnits="objectBoundingBox">
              <path clipRule="evenodd" d="M0.286,0 H0.035 A0.035,0.104,0,0,0,0,0.104 v0.793 a0.035,0.104,0,0,0,0.035,0.104 h0.118 c0.011,0,0.022,-0.017,0.029,-0.041 c0.017,-0.055,0.046,-0.122,0.078,-0.122 h0.481 c0.032,0,0.06,0.067,0.078,0.122 c0.008,0.024,0.018,0.041,0.029,0.041 H0.965 a0.035,0.104,0,0,0,0.035,-0.104 V0.104 a0.035,0.104,0,0,0,-0.035,-0.104 H0.286"/>
            </clipPath>
          </defs>
        </svg>
        <Image
          className={styles['bg-image']}
          style={{ clipPath: `url(#${bgImageClipPathId})` }}
          src={bgImg}
          alt="Planets illustration"
          objectFit="cover"
        />
        <img
          className={styles['speech-image']}
          src={speechImg}
          srcSet={`${speech2xImg} 2x`}
          alt="Speech bubble"
        />
        <img
          className={styles['smiley-image']}
          src={smileyImg}
          srcSet={`${smiley2xImg} 2x`}
          alt="Smiley emoji"
        />
        <img
          className={styles['star-image']}
          src={starImg}
          srcSet={`${star2xImg} 2x`}
          alt="Star"
        />
        <div className={styles.titles}>
          <span>Affiliate</span>
          <span className="ellipsis-ml">Invite your friends to get free coins</span>
        </div>
        <StatsBar
          className={styles['stats-bar']}
          numReferrals={200}
          totalDeposit={3071}
          level={2}
        />
      </div>
      <LinkBar
        className={styles['link-bar']}
        url="https://wenbet.com.company/p/mZLVSmUbO3"
      />
    </div>
  );
});

export default Content;
