import { useEffect, useRef } from 'react';

export default function SvgAnim({
  className,
  attributeName,
  type,
  from,
  to,
  play,
  ...all
}) {
  const ref = useRef();
  useEffect(() => {
    if (play) {
      ref.current.beginElement();
    }
  }, [play]);
  const Tag = attributeName === 'transform' ? 'animateTransform' : 'animate';
  return (
      <Tag
          ref={ref}
          attributeName={attributeName}
          attributeType="XML"
          type={type}
          from={from}
          to={to}
          dur="200ms"
          begin="indefinite"
          fill="freeze"
          calcMode="spline"
          keyTimes="0;1"
          keySplines="0 0 .58 1"
          {...all}
      />
  );
}
