import styles from './promo-form.module.scss';

import CtaButton from 'comps/cta-button';

export default function PromoForm({
  className,
  isInline,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isInline) classNames.push(styles.inline);
  return (
    <form
      className={classNames.join(' ')}
      onSubmit={e => {
        e.preventDefault();
        console.log('Apply promo code');
      }}
      {...all}
    >
      <input type="text" placeholder="Promo code" />
      {isInline ? 
        <input
          className={styles['inline-button']}
          type="submit"
          value="Activate"
        /> :
        <CtaButton
          className={styles['cta-button']}
          type="submit"
        >Activate</CtaButton>}
    </form>
  );
}
