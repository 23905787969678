import { useDeviceContext } from 'context/device';

import styles from './player.module.scss';

import Image from 'comps/image';

import playerImg from 'assets/images/player.png';
import player2xImg from 'assets/images/player-2x.png';

export default function Player({
  className,
  name,
  amount,
  multiplier,
  bank,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <div className={classNames.join(' ')} {...all}>
      <div className={styles.profile}>
        <Image
          className={styles.image}
          src={playerImg}
          srcSet={`${player2xImg} 2x`}
          alt="Player"
          objectFit="cover"
        />
        {!isMobile && <span className={`${styles.name} ellipsis`}>{name}</span>}
      </div>
      <span className={styles.amount}>{amount}</span>
      <div className={styles.multiplier}>
        <span>{multiplier}</span>
      </div>
      <span className={styles.bank}>{bank}</span>
    </div>
  );
}
