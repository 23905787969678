import React from 'react';
import styles from './image.module.scss';

const Image = React.forwardRef(({
  className,
  src,
  srcSet,
  alt,
  objectFit,
  ...all
}, ref) => {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <div ref={ref} className={classNames.join(' ')} {...all}>
      <img
        className="contained"
        src={src}
        srcSet={srcSet}
        alt={alt}
        style={{
          objectFit: objectFit,
        }}
      />
    </div>
  );
});

export default Image;
