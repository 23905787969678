import { useDeviceContext } from 'context/device';

import styles from './text-modal.module.scss';

import SimpleModal from 'modals/simple-modal';
import ScrollArea from 'comps/scroll-area';

export default function TextModal({
  className,
  title,
  height = 600,
  children,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <SimpleModal
      className={classNames.join(' ')}
      title={title}
      style={{ height: `${height}px` }}
      {...all}
    >
      <ScrollArea className={styles['scroll-area']}>
        <div className={styles.content}>
          {children}
        </div>
      </ScrollArea>
    </SimpleModal>
  );
}
