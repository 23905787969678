import styles from './contained-scroll-area.module.scss';

export default function ContainedScrollArea({
  className,
  isHorizontal = false,
  isEnabled = true,
  children,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isHorizontal) classNames.push(styles.horizontal);
  return (
    <div className={classNames.join(' ')} {...all}>
      {isEnabled ? <div className={`${styles.inner} contained no-scrollbar`}>
        {children}
      </div> : children}
    </div>
  );
}
