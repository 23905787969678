import { useDeviceContext } from 'context/device';
import { useWindowSize } from 'hooks/misc';
import { useEffect, useRef } from 'react';
import { usdFormatter } from 'utils';

import styles from './entry.module.scss';

import Player from './player';

export default function Entry({
  className,
  position,
  imgSrc,
  name,
  wagered,
  prize,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const windowSize = useWindowSize();
  const container = useRef();
  useEffect(() => {
    if (windowSize.width && container.current) {
      // This is a workaround to achieve linear gradients in the 
      // background of table rows on Safari when using 
      // `background-attachment: fixed`.
      const rect = container.current.getBoundingClientRect();
      const leftOffset = rect.left / windowSize.width;
      const rightOffset = (rect.left + rect.width) / windowSize.width;
      container.current.style.setProperty('--left-offset-perc', `${100 * leftOffset}%`);
      container.current.style.setProperty('--right-offset-perc', `${100 * rightOffset}%`);
    }
  }, [windowSize, container.current])
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  if (position <= 3) {
    classNames.push(
      styles.podium,
      styles[`pos-${position}`]);
  }
  return (
    <tr
      ref={container}
      className={classNames.join(' ')}
      {...all}
    >
      <td className={styles['player-cell']}>
        <Player
          className={styles.player}
          position={position}
          imgSrc={imgSrc}
          name={name}
        />
      </td>
      <td className={styles.amount}>
        {usdFormatter.format(wagered)}
      </td>
      <td className={styles.amount}>
        {usdFormatter.format(prize)}
      </td>
      {!isMobile && <td className={styles['medal-cell']}>
        {/* medal */}
      </td>}
    </tr>
  );
}
