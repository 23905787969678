import { useModal } from '@ebay/nice-modal-react';
import { useDeviceContext } from 'context/device';

import styles from './modal.module.scss';

import Blocker from './blocker';
import CloseButton from './close-button';

export default function Modal({
  className,
  hasCloseButton = true,
  children,
  ...all
}) {
  const modal = useModal();
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <Blocker
      isVisible={modal.visible}
      onClick={modal.hide}
    >
      <div
        className={classNames.join(' ')}
        {...all}
      >
        {children}
        {hasCloseButton && <CloseButton
          className={styles['close-button']}
          onClick={modal.hide}
        />}
      </div>
    </Blocker>
  );
}
