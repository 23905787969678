import { useDeviceContext } from 'context/device';
import styles from './cta-button.module.scss';

export default function CtaButton({
  className,
  isRed,
  children,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container, 'gradient-border-box'];
  if (className) classNames.push(className);
  if (isRed) classNames.push(styles.red);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <button className={classNames.join(' ')} {...all}>
      {children}
    </button>
  );
}
