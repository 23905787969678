import styles from './mobile-navbar.module.scss';

import MenuToggle from './menu-toggle';
import { Link } from 'react-router-dom';
import Logo from 'layout/logo';
import UserDropdown from 'layout/user-dropdown';

export default function MobileNavbar({
  className,
  isMenuToggleChecked,
  onMenuToggleChange,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <div className={classNames.join(' ')} {...all}>
      <MenuToggle
        className={styles['menu-toggle']}
        isChecked={isMenuToggleChecked}
        onChange={onMenuToggleChange}
      />
      <Link to="/">
        <Logo
          id="mobile-navbar-logo"
          className={styles.logo}
        />
      </Link>
      <div className={styles.spacer}></div>
      <button
        className={styles['notification-button']}
        onClick={() => alert('Show notifications')}
      >
        <div>
          <svg viewBox="0 0 20 21" width="20" fill="#BFCAE4">
            <path d="m19.99 16.97-.22-.19a9.18 9.18 0 0 1-1.6-1.87c-.5-.96-.79-2-.87-3.07V8.68a6.9 6.9 0 0 0-6.03-6.87V1a.85.85 0 0 0-1.7 0v.83A6.9 6.9 0 0 0 3.6 8.68v3.16a8.07 8.07 0 0 1-.87 3.07 9.2 9.2 0 0 1-1.57 1.87l-.22.2v1.8h19.04v-1.8Zm-11.2 2.47a1.7 1.7 0 0 0 3.35 0H8.8Z"/>
          </svg>
        </div>
      </button>
      <UserDropdown
        className={styles['user-dropdown']}
      />
    </div>
  );
}
