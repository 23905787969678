import { useEffect, useRef, useState } from 'react';
import { useAnimate, useInterval } from 'hooks/misc';
import { smoothDamp } from 'math';
import { useDeviceContext } from 'context/device';

import styles from './chart.module.scss';

import Rocket from './rocket';
import Line from './line';

const config = {
  dataUpdateIntervalMs: 100,
  multiplierScaler: 0.00006,
  minXRange: 10,
  minYRange: 2,
  xScalePerc: 0.816,
  yScalePerc: 0.816,
  xOffsetPerc: 0.035,
  rocketSmoothing: 0.17,
  lineRectCornerOffset: 3,
  maxRoundSeconds: 20,
};

function createDataPoint(x, y) {
  return { x, y };
}
function getScaleMin(lastDataPoint) {
  return {
    x: Math.max(lastDataPoint.x, config.minXRange) * (1 - 1 / (1 - config.xOffsetPerc)),
    y: 1,
  };
}
function getScaleMax(lastDataPoint) {
  return {
    x: Math.max(lastDataPoint.x / config.xScalePerc, config.minXRange),
    y: Math.max(lastDataPoint.y / config.yScalePerc, config.minYRange),
  };
}

export default function Chart({
  className,
  ...all
}) {
  const { isMobile, isTablet } = useDeviceContext();

  /* ——— Main references ——— */
  const container = useRef();
  const lineContainer = useRef();
  const lineSvg = useRef();
  const linePath = useRef();
  const collectLineRefs = () => {
    lineSvg.current = lineSvg.current ?? lineContainer.current.querySelector('svg');
    linePath.current = linePath.current ?? lineContainer.current.querySelector('path');
  };
  useEffect(collectLineRefs, []);

  /* ——— Randomly end the round at some point ——— */
  const [isRoundOver, setRoundOver] = useState(false);
  useEffect(() => {
    setTimeout(() => setRoundOver(true), 1000 * Math.random() * config.maxRoundSeconds)
  }, []);

  /* ——— Data update ——— */
  const time = useRef(0);
  const multiplier = useRef(1);
  const [data, setData] = useState([createDataPoint(time.current, multiplier.current)]);
  useInterval(() => {
    if (!container.current || isRoundOver) return;
    time.current += config.dataUpdateIntervalMs / 1000;
    multiplier.current += config.dataUpdateIntervalMs * config.multiplierScaler * multiplier.current;
    setData(prevData => {
      const newData = prevData.slice();
      newData.push(createDataPoint(time.current, multiplier.current));
      return newData;
    });
  }, config.dataUpdateIntervalMs);

  /* ——— Rocket targets ——— */
  const rocketTargetPos = useRef();
  const rocketTargetAngle = useRef();
  const updateRocketTargets = (data) => {
    // Ensure all refs are available
    if (!container.current || !lineSvg.current || !linePath.current || data.length < 2) {
      return;
    }
    const containerRect = container.current.getBoundingClientRect();
    const linePathRect = linePath.current.getBoundingClientRect();
    // Position
    rocketTargetPos.current = {
      x: linePathRect.left + linePathRect.width - config.lineRectCornerOffset - containerRect.left - 0.5 * rocket.current.clientWidth,
      y: linePathRect.top + config.lineRectCornerOffset - containerRect.top - 0.5 * rocket.current.clientHeight,
    };
    // Angle
    const lastDataPoint = data[data.length - 1];
    const prevLastDataPoint = data[data.length - 2];
    const scaleMin = getScaleMin(lastDataPoint);
    const scaleMax = getScaleMax(lastDataPoint);
    const scaleDataPoint = (dataPoint) => {
      return {
        x: lineSvg.current.clientWidth * (dataPoint.x - scaleMin.x) / (scaleMax.x - scaleMin.x),
        y: lineSvg.current.clientHeight * (dataPoint.y - scaleMin.y) / (scaleMax.y - scaleMin.y),
      };
    };
    const prevDataPointScaled = scaleDataPoint(lastDataPoint);
    const prevPrevDataPointScaled = scaleDataPoint(prevLastDataPoint);
    const prevDataVector = {
      x: prevDataPointScaled.x - prevPrevDataPointScaled.x,
      y: prevDataPointScaled.y - prevPrevDataPointScaled.y,
    };
    rocketTargetAngle.current = -Math.atan(prevDataVector.y / prevDataVector.x);
  };
  useEffect(() => {
    collectLineRefs();
    updateRocketTargets(data);
  }, [data]);

  /* ——— Rocket update ——— */
  const rocket = useRef();
  const rocketPos = useRef();
  const rocketPosVel = useRef({ x: 0, y: 0 });
  const rocketAngle = useRef(0);
  const rocketAngleVel = useRef(0);
  useAnimate((deltaTime) => {
    if (!rocketTargetPos.current || !rocketTargetAngle.current) {
      return;
    }
    if (!rocketPos.current) setRocketPos(rocketTargetPos.current);
    if (!rocketAngle.current) setRocketAngle(rocketTargetAngle.current);
    [rocketPos.current.x, rocketPosVel.current.x] = smoothDamp(
      rocketPos.current.x,
      rocketTargetPos.current.x,
      rocketPosVel.current.x,
      config.rocketSmoothing,
      Number.MAX_SAFE_INTEGER,
      deltaTime / 1000);
    [rocketPos.current.y, rocketPosVel.current.y] = smoothDamp(
      rocketPos.current.y,
      rocketTargetPos.current.y,
      rocketPosVel.current.y,
      config.rocketSmoothing,
      Number.MAX_SAFE_INTEGER,
      deltaTime / 1000);
    [rocketAngle.current, rocketAngleVel.current] = smoothDamp(
      rocketAngle.current,
      rocketTargetAngle.current,
      rocketAngleVel.current,
      config.rocketSmoothing,
      Number.MAX_SAFE_INTEGER,
      deltaTime / 1000);
    setRocketPos(rocketPos.current);
    setRocketAngle(rocketAngle.current);
  });
  const setRocketPos = (coords) => {
    rocket.current.style.left = `${coords.x}px`;
    rocket.current.style.top = `${coords.y}px`;
    rocketPos.current = coords;
  };
  const setRocketAngle = (rads) => {
    rocket.current.style.transform = `rotate(${rads}rad)`;
    rocketAngle.current = rads;
  };

  // DOM
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isRoundOver) classNames.push(styles['round-over']);
  if (isMobile) classNames.push(styles.mobile);
  const lastDataPoint = data[data.length - 1];
  const scaleMin = getScaleMin(lastDataPoint);
  const scaleMax = getScaleMax(lastDataPoint);
  return (
    <div
      ref={container}
      className={classNames.join(' ')}
      {...all}
    >
      <svg
        className="lin-grad-svg"
        width="0"
        height="0"
      >
        <defs>
          <linearGradient
            id="chart-line-gradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="#6B84FF"/>
            <stop offset="100%" stopColor="#AC61D4"/>
          </linearGradient>
        </defs>
      </svg>
      <div
        ref={lineContainer}
        className={styles['line-container']}
      >
        <Line
          data={[{
            id: "multiplier",
            data: data,
          }]}
          xMin={scaleMin.x}
          xMax={scaleMax.x}
          yMin={scaleMin.y}
          yMax={scaleMax.y}
          hideAxes={isMobile}
        />
      </div>
      <Rocket
        ref={rocket}
        className={styles.rocket}
        scale={isMobile ? 0.57 : (isTablet ? 0.79 : 1)}
        isVisible={data.length >= 2}
        isExploded={isRoundOver}
      />
      <div className={styles.display}>
        <span>Round over</span>
        <span>{lastDataPoint.y.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}x</span>
      </div>
    </div>
  );
}
