export default function Icon({ id, ...all }) {
  const linGradId = `${id}-lin-grad`;
  return (
    <svg id={id} viewBox="0 0 18 18" width="18" fill={`url(#${linGradId})`} {...all}>
      <path d="M15.6 4.6H1.85v-.55l12.1-.97v.97h1.65V2.4c0-1.21-.98-2.06-2.18-1.89L2.38 2.1A2.62 2.62 0 0 0 .2 4.6v11a2.2 2.2 0 0 0 2.2 2.2h13.2a2.2 2.2 0 0 0 2.2-2.2V6.8a2.2 2.2 0 0 0-2.2-2.2Zm-1.65 7.7a1.65 1.65 0 1 1 0-3.3 1.65 1.65 0 0 1 0 3.3Z"/>
      <defs>
        <linearGradient id={linGradId} x1="9" y1=".49" x2="9" y2="17.8" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4F5EEA"/>
          <stop offset="1" stopColor="#769CFF"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
