import styles from './scroll-area.module.scss';

import * as RadixScrollArea from '@radix-ui/react-scroll-area';

export default function ScrollArea({
  className,
  viewportRef,
  children,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <RadixScrollArea.Root
      className={classNames.join(' ')}
      type="scroll"
      {...all}
    >
      <RadixScrollArea.Viewport
        ref={viewportRef}
        className={`${styles.viewport} contained`}
      >
        {children}
      </RadixScrollArea.Viewport>
      <RadixScrollArea.Scrollbar
        className={styles.scrollbar}
        orientation="vertical"
      >
        <RadixScrollArea.Thumb className={styles.thumb} />
      </RadixScrollArea.Scrollbar>
    </RadixScrollArea.Root>
  );
}
