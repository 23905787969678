import styles from './sidebar.module.scss';
import { useLocation } from 'react-router-dom';
import { games } from 'data';

import * as NavMenu from '@radix-ui/react-navigation-menu';
import ScrollArea from 'comps/scroll-area';
import LinkItem from 'layout/nav-menu/link-item/LinkItem';

export default function Sidebar({
  className,
  ...all
}) {
  const location = useLocation();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <NavMenu.Root
      className={classNames.join(' ')}
      orientation="vertical"
      {...all}
    >
      <ScrollArea className={styles['scroll-area']}>
        <NavMenu.List className={styles.links}>
          {games.map((data, i) => {
            const classNames = [styles.link];
            if (location.pathname === data.path) {
              classNames.push(styles.active);
            }
            return (
              <LinkItem
                key={i}
                className={classNames.join(' ')}
                to={data.path}
                isDisabled={!data.available}
              >
                <data.icon.Component width={data.icon.width.desktop} />
                <span>{data.name}</span>
              </LinkItem>
            );
          })}
        </NavMenu.List>
      </ScrollArea>
    </NavMenu.Root>
  );
}
