import styles from './multiplier.module.scss';

export default function Multiplier({
  className,
  amount,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (amount >= 2) classNames.push(styles.high);
  return (
    <span className={classNames.join(' ')} {...all}>{amount}x</span>
  );
}
