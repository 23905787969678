import NiceModal from '@ebay/nice-modal-react';

import TextModal from 'modals/text-modal';

const PrivacyPolicyModal = ({ ...all }) => {
  return (
    <TextModal title="Privacy policy" {...all}>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem donec massa sapien faucibus et molestie ac. Cursus mattis molestie a iaculis at. Suscipit adipiscing bibendum est ultricies integer quis. Sit amet nisl purus in mollis nunc sed id semper. In metus vulputate eu scelerisque felis. Amet nisl purus in mollis nunc sed. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat. Urna porttitor rhoncus dolor purus non enim praesent elementum. Dolor sed viverra ipsum nunc aliquet bibendum enim facilisis gravida. Vel pharetra vel turpis nunc eget lorem. Vitae justo eget magna fermentum. Gravida in fermentum et sollicitudin ac orci phasellus. Faucibus interdum posuere lorem ipsum dolor sit amet consectetur adipiscing. Pretium quam vulputate dignissim suspendisse. Ut sem viverra aliquet eget sit amet tellus. Integer eget aliquet nibh praesent tristique magna.</p>
      <p>In eu mi bibendum neque egestas. Sed id semper risus in hendrerit. Gravida in fermentum et sollicitudin ac. Tempor id eu nisl nunc mi ipsum. Massa sapien faucibus et molestie ac feugiat sed lectus. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus. Nunc non blandit massa enim nec dui. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Tellus rutrum tellus pellentesque eu tincidunt. Non consectetur a erat nam. Quis hendrerit dolor magna eget. Nisi est sit amet facilisis magna etiam tempor orci eu. Lorem donec massa sapien faucibus et molestie ac feugiat. Nec feugiat in fermentum posuere urna nec tincidunt praesent semper. Ut lectus arcu bibendum at. Dui faucibus in ornare quam viverra orci sagittis.</p>
      <p>Ornare lectus sit amet est placerat in. Commodo elit at imperdiet dui accumsan sit amet nulla. Id interdum velit laoreet id donec ultrices tincidunt. Nulla pharetra diam sit amet. Sit amet mattis vulputate enim nulla. Id neque aliquam vestibulum morbi blandit cursus. A cras semper auctor neque vitae tempus quam. Velit euismod in pellentesque massa placerat duis. Nam aliquam sem et tortor consequat. Bibendum arcu vitae elementum curabitur vitae nunc. Euismod in pellentesque massa placerat. Imperdiet proin fermentum leo vel. Eget nunc lobortis mattis aliquam faucibus. Mattis enim ut tellus elementum sagittis vitae et. Euismod in pellentesque massa placerat. Iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui. Orci eu lobortis elementum nibh. Interdum consectetur libero id faucibus nisl tincidunt eget.</p>
    </TextModal>
  );
};

export default NiceModal.create(PrivacyPolicyModal);
