import { useLocation } from 'react-router-dom';
import { games, navLinks } from 'data';
import styles from './mobile-sidebar.module.scss';

import * as NavMenu from '@radix-ui/react-navigation-menu';
import ScrollArea from 'comps/scroll-area';
import LinkItem from 'layout/nav-menu/link-item';
import TriggerItem from 'layout/nav-menu/trigger-item';

export default function MobileSidebar({
  className,
  isVisible,
  onClickItem,
  ...all
}) {
  const location = useLocation();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isVisible) classNames.push(styles.visible);
  return (
    <NavMenu.Root
      className={classNames.join(' ')}
      orientation="vertical"
      {...all}
    >
      <ScrollArea className={styles['scroll-area']}>
        <NavMenu.List className={styles.list}>
          <div className={styles.games}>
            {games.map((data, i) => {
              const classNames = [styles['game-link']];
              if (location.pathname === data.path) {
                classNames.push(styles.active);
              }
              return (
                <LinkItem
                  key={i}
                  className={classNames.join(' ')}
                  to={data.path}
                  isDisabled={!data.available}
                  onClick={onClickItem}
                >
                  <div>
                    <data.icon.Component width={data.icon.width.mobile} />
                  </div>
                  <span>{data.name}</span>
                </LinkItem>
              );
            })}
          </div>
          <hr />
          <div className={styles['nav-links']}>
            {navLinks.map((data, i) => {
              const classNames = [styles['nav-link']];
              if (data.action) {
                return (
                  <TriggerItem
                    key={i}
                    className={classNames.join(' ')}
                    onClick={() => {
                      data.action();
                      if (onClickItem) {
                        onClickItem();
                      }
                    }}
                  >{data.name}</TriggerItem>
                );
              }
              if (location.pathname === data.path) {
                classNames.push(styles.active);
              }
              return (
                <LinkItem
                  key={i}
                  className={classNames.join(' ')}
                  to={data.path}
                  onClick={onClickItem}
                >{data.name}</LinkItem>
              );
            })}
          </div>
        </NavMenu.List>
      </ScrollArea>
    </NavMenu.Root>
  );
}
