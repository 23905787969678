import NiceModal from '@ebay/nice-modal-react';
import styles from './navbar.module.scss';
import { useLocation } from 'react-router-dom';
import { navLinks } from 'data';

import * as NavMenu from '@radix-ui/react-navigation-menu';
import { Link } from 'react-router-dom';
import Logo from 'layout/logo';
import LinkItem from 'layout/nav-menu/link-item/LinkItem';
import TriggerItem from 'layout/nav-menu/trigger-item';
import NotificationButton from './notification-button';
import Balance from './balance';
import UserDropdown from 'layout/user-dropdown';

import DepositModal from 'modals/deposit-modal';

export default function Navbar({
  className,
  ...all
}) {
  const location = useLocation();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <NavMenu.Root className={classNames.join(' ')} {...all}>
      <div className={styles.left}>
        <Link className={styles.branding} to="/">
          <Logo id="navbar-logo" />
          <h1>Wenbet</h1>
        </Link>
        <NavMenu.List className={styles.list}>
          {navLinks.map((data, i) => {
            if (data.action) {
              return (
                <TriggerItem
                  key={i}
                  className={styles['list-item']}
                  onClick={data.action}
                >{data.name}</TriggerItem>
              );
            }
            const classNames = [styles['list-item']];
            if (location.pathname === data.path) {
              classNames.push(styles.active);
            }
            return (
              <LinkItem
                key={i}
                className={classNames.join(' ')}
                to={data.path}
              >{data.name}</LinkItem>
            );
          })}
        </NavMenu.List>
      </div>
      <div className={styles.right}>
        <NotificationButton
          className={styles['notification-button']}
          onClick={() => alert('Show notifications')}
        />
        <Balance
          className={styles.balance}
          onClickAdd={() => NiceModal.show(DepositModal)}
        />
        <UserDropdown className={styles['user-dropdown']} />
      </div>
    </NavMenu.Root>
  );
}
