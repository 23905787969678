import { useDeviceContext } from 'context/device';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import styles from './layout.module.scss';

import Navbar from './navbar';
import Sidebar from './sidebar';
import Chatbar from './chatbar';
import ContentBlocker from './content-blocker';

import MobileNavbar from './mobile-navbar';
import MobileSidebar from './mobile-sidebar';
import { useMediaQuery } from 'react-responsive';

const bgWidths = [1239, 1409, 1793, 3000];
const bgSrcSet = bgWidths.map(width => {
  const img = require(`assets/images/bg-${width}w.jpg`);
  return `${img} ${width}w`;
}).join(',');

export default function Layout({
  className,
  children,
  ...all
}) {
  const { isMobile, isTablet } = useDeviceContext();
  const isNavbarTooWide = useMediaQuery({ maxWidth: 1023 });
  const location = useLocation();
  const isLeaderboard = location.pathname === '/leaderboard';
  const isMobileNavigation = isMobile || isNavbarTooWide;
  const [isMobileSidebarVisible, setMobileSidebarVisible] = useState(false);
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  if (isLeaderboard) classNames.push(styles.leaderboard);
  return (
    <div className={classNames.join(' ')} {...all}>
      {isMobileNavigation ? <MobileNavbar
          className={styles['mobile-navbar']}
          isMenuToggleChecked={isMobileSidebarVisible}
          onMenuToggleChange={setMobileSidebarVisible}
        /> : <Navbar className={styles.navbar} />}
      <div className={styles.content}>
        {isMobileNavigation ? <MobileSidebar
            className={styles['mobile-sidebar']}
            isVisible={isMobileSidebarVisible}
            onClickItem={() => setMobileSidebarVisible(false)}
          /> : <Sidebar className={styles.sidebar} />}
        <div className={styles.page}>
          <img
            className={`${styles.backdrop} contained`}
            srcSet={bgSrcSet}
            alt="Illustration of planets and shooting stars in space."
            draggable={false}
          />
          <main className={styles.main}>
            {children}
          </main>
          {!isMobile && !isTablet && <Chatbar className={styles.chatbar} />}
        </div>
        <ContentBlocker
          className={styles['content-blocker']}
          isVisible={isMobileSidebarVisible}
          onClick={() => setMobileSidebarVisible(false)}
        />
      </div>
    </div>
  );
}
