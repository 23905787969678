import React, { useEffect, useState } from 'react';
import styles from './fade-in.module.scss';

const FadeIn = React.forwardRef(({
  tag = 'div',
  className,
  children,
  ...all
}, ref) => {
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
  }, []);
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isVisible) classNames.push(styles.visible);
  const Tag = tag;
  return (
    <Tag ref={ref} className={classNames.join(' ')} {...all}>
      {children}
    </Tag>
  );
});

export default FadeIn;
