import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import styles from './user-dropdown.module.scss';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Image from 'comps/image';
import DepositModal from 'modals/deposit-modal';

import avatarImg from 'assets/images/avatar.png';
import avatar2xImg from 'assets/images/avatar-2x.png';

export default function UserDropdown({
  className,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={classNames.join(' ')} {...all}>
        <Image
          className={styles.image}
          src={avatarImg}
          srcSet={`${avatar2xImg} 2x`}
          alt="Avatar"
          objectFit="cover"
        />
        <svg viewBox="0 0 10 6" width="10" fill="#fff">
          <path d="M1.5.2 5 3.7 8.5.2l1.4.7L5 5.8.1.9 1.5.2Z"/>
        </svg>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          <DropdownMenu.Item onSelect={() => NiceModal.show(DepositModal)}>
            Deposit
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
