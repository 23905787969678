import { useDeviceContext } from 'context/device';
import { usdFormatter } from 'utils';

import styles from './stats-bar.module.scss';

export default function StatsBar({
  className,
  numReferrals,
  totalDeposit,
  level,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container, 'no-scrollbar'];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <div className={classNames.join(' ')} {...all}>
      <div className={styles.stat}>
        <svg viewBox="0 0 35 27" width={isMobile ? 21 : 35}>
          <path d="M15.65 6.1a5.55 5.55 0 1 1-11.1 0 5.55 5.55 0 0 1 11.1 0Zm14.8 0a5.55 5.55 0 1 1-11.1 0 5.55 5.55 0 0 1 11.1 0Zm-7.53 20.35a12.9 12.9 0 0 0-2.65-9.86 9.25 9.25 0 0 1 13.88 8.01v1.85H22.92ZM10.1 15.35a9.25 9.25 0 0 1 9.25 9.25v1.85H.85V24.6a9.25 9.25 0 0 1 9.25-9.25Z" fill="#C8DEFF"/>
        </svg>
        <div className={styles.text}>
          <span>{numReferrals}</span>
          <span>Referrals</span>
        </div>
      </div>
      <div className={styles.stat}>
        <svg viewBox="0 0 27 29" width={isMobile ? 16 : 27}>
          <path fillRule="evenodd" clipRule="evenodd" d="M13.53.63A19.86 19.86 0 0 0 3.8 3.4l2.13 3.14 1 .4a15.23 15.23 0 0 0 13.13 0l1.14-.59 2.02-2.95a12.8 12.8 0 0 0-1.3-.74A19.53 19.53 0 0 0 13.53.62ZM8.56 4.17c-.75-.14-1.5-.33-2.23-.56a18.3 18.3 0 0 1 7.2-1.6c1.96 0 3.8.39 5.33.9-1.79.25-3.7.67-5.52 1.2a10.6 10.6 0 0 1-4.78.06Zm12.3 3.9-.18.1a16.62 16.62 0 0 1-14.38 0l-.18-.09C-.37 15.2-5.33 28.36 13.53 28.36s13.78-13.4 7.34-20.28Zm-8.13 6.42a1.54 1.54 0 1 0 0 3.08V14.5Zm1.54-1.54v-.77h-1.54v.77a3.08 3.08 0 0 0 0 6.16v3.09a1.54 1.54 0 0 1-1.45-1.03.77.77 0 1 0-1.46.51 3.08 3.08 0 0 0 2.9 2.06v.77h1.55v-.77a3.08 3.08 0 0 0 0-6.17V14.5c.67 0 1.24.43 1.46 1.03a.77.77 0 1 0 1.45-.52 3.08 3.08 0 0 0-2.9-2.05Zm0 6.16v3.09a1.54 1.54 0 0 0 0-3.09Z" fill="#C8DEFF"/>
        </svg>
        <div className={styles.text}>
          <span>{usdFormatter.format(totalDeposit)}</span>
          <span>{isMobile ? 'Deposit' : 'Total deposit'}</span>
        </div>
      </div>
      <div className={styles.stat}>
        <svg viewBox="0 0 31 29" width={isMobile ? 19 : 31}>
          <path fillRule="evenodd" clipRule="evenodd" d="M.08 10.26 15.5 28.38l15.42-18.12-4.4-9.63H4.48l-4.4 9.63Zm10.75-1.71a2 2 0 0 0-3 2.65l6.18 6.94a2 2 0 0 0 2.98 0l6.17-6.94a2 2 0 0 0-2.99-2.65L15.5 13.8l-4.67-5.25Z" fill="#C8DEFF"/>
        </svg>
        <div className={styles.text}>
          <span>{level}</span>
          <span>{isMobile ? 'Level' : 'Your level'}</span>
        </div>
      </div>
    </div>
  );
}
