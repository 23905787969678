import NiceModal from '@ebay/nice-modal-react';
import { useDeviceContext } from 'context/device';

import styles from './verify-result-modal.module.scss';

import SimpleModal from 'modals/simple-modal';
import * as Label from '@radix-ui/react-label';
import CtaButton from 'comps/cta-button';

const VerifyResultModal = ({
  className,
  ...all
}) => {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  const idPrefix = 'verify-result-modal';
  const hashInputId = `${idPrefix}-hash-input`;
  return (
    <SimpleModal
      className={classNames.join(' ')}
      title="Verify result"
      {...all}
    >
      <form
        className={styles.content}
        onSubmit={e => {
          e.preventDefault();
          console.log('Verify result');
        }}
      >
        <p>You can use the below form to prove the results of each games.<br/>Just enter target hash to below form and click on the button.</p>
        <Label.Root htmlFor={hashInputId}>Hash</Label.Root>
        <input id={hashInputId} type="text" />
        <CtaButton
          className={styles['confirm-button']}
          type="submit"
        >Check result</CtaButton>
      </form>
    </SimpleModal>
  );
};

export default NiceModal.create(VerifyResultModal);
