import styles from './auto-cash-out.module.scss';

import * as Label from '@radix-ui/react-label';
import TextInput from 'comps/text-input';

export default function AutoCashOut({
  id,
  className,
  value,
  onChange,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  const inputId = `${id}-input`;
  return (
    <div className={classNames.join(' ')} {...all}>
      <Label.Root htmlFor={inputId}>Auto cash out</Label.Root>
      <TextInput
        className="no-spin"
        type="number"
        id={inputId}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
