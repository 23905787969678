import styles from './heading.module.scss';

export default function Heading({
  className,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <div className={classNames.join(' ')} {...all}>
      <svg viewBox="0 0 161 58" width="161" fill="url(#content-title-button-lin-grad)">
        <path d="M0 9a9 9 0 0 1 9-9h142.79c8.57 0 12.29 10.84 5.53 16.1l-51.38 40a9 9 0 0 1-5.53 1.9H9a9 9 0 0 1-9-9V9Z"/>
        <defs>
          <linearGradient
            id="content-title-button-lin-grad"
            x1="89"
            y1="0"
            x2="89"
            y2="58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5970FC"/>
            <stop offset="1" stopColor="#3E54FC"/>
          </linearGradient>
        </defs>
      </svg>
      <span>Crash</span>
    </div>
  );
}
