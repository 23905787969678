import { createContext, useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

const DeviceContext = createContext({
  isMobile: false,
  isTablet: false,
});

export const DeviceContextProvider = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });
  const value = useMemo(() => ({
    isMobile,
    isTablet,
  }), [
    isMobile,
    isTablet,
  ]);
  return (
    <DeviceContext.Provider value={value}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDeviceContext = () => {
  return useContext(DeviceContext);
};
