import { usdFormatter } from 'utils';
import { useDeviceContext } from 'context/device';
import styles from './user.module.scss';

import Image from 'comps/image';

import playerImg from 'assets/images/player.png';
import player2xImg from 'assets/images/player-2x.png';

export default function User({
  className,
  name,
  amount,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return ( 
    <div className={classNames.join(' ')} {...all}>
      <div className={styles.profile}>
        <Image
          className={styles.image}
          src={playerImg}
          srcSet={`${player2xImg} 2x`}
          alt="User"
          objectFit="cover"
        />
        <span className="ellipsis">{name}</span>
      </div>
      <div className={styles.spacer}></div>
      <span className={styles.amount}>{usdFormatter.format(amount)}</span>
    </div>
  );
}
