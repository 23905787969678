import styles from './tab-list.module.scss';

import * as Tabs from '@radix-ui/react-tabs';

export default function TabList({
  className,
  datas,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <Tabs.List
      className={classNames.join(' ')}
      aria-label="Betting style"
      {...all}
    >
      {datas.map(data => {
        return (
          <Tabs.Trigger
            key={data.value}
            className={styles.tab}
            value={data.value}
          >
            <h3>{data.name}</h3>
            <hr />
          </Tabs.Trigger>
        );
      })}
    </Tabs.List>
  );
}
