import React from 'react';
import Lottie from 'react-lottie-player';
import styles from './rocket.module.scss';

import * as flyingAnim from 'assets/lotties/rocket-flying.json';
import * as breakingAnim from 'assets/lotties/rocket-breaking.json';
import * as explosionAnim from 'assets/lotties/explosion.json';

const Rocket = React.forwardRef(({
  className,
  scale = 1,
  isVisible,
  isExploded,
  ...all
}, ref) => {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isVisible) classNames.push(styles.visible);
  return (
    <div
      ref={ref}
      className={classNames.join(' ')}
      style={{ '--scale': scale }}
      {...all}
    >
      <div className={styles.inner}>
        {!isExploded && <Lottie
          className={`${styles.lottie} ${styles.flying}`}
          loop={true}
          animationData={flyingAnim}
          play
        />}
        {isExploded && <>
          <Lottie
            className={`${styles.lottie} ${styles.breaking}`}
            loop={false}
            animationData={breakingAnim}
            play
          />
          <Lottie
            className={`${styles.lottie} ${styles.explosion}`}
            loop={false}
            animationData={explosionAnim}
            play
          />
        </>}
      </div>
    </div>
  );
});

export default Rocket;
