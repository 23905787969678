import { useDeviceContext } from 'context/device';
import { usdFormatter } from 'utils';

import styles from './stats.module.scss';

const progressPathLength = 278;

export default function Stats({
  className,
  level,
  currentAmount,
  nextLevelAmount,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  const progress = currentAmount / nextLevelAmount;
  return (
    <div className={classNames.join(' ')} {...all}>
      <div className={styles['level-info']}>
        <div className={styles.dial}>
          <svg viewBox="0 0 124 106" width="124" fill="none" strokeWidth="6">
            <path
              d="M103.72 103.72a58.99 58.99 0 1 0-83.44 0"
              stroke="#545968"
              strokeDasharray="10 9"
            />
            <path
              d="M103.72 103.72a58.99 58.99 0 1 0-83.44 0"
              stroke="#5873FF"
              strokeDasharray={progressPathLength}
              strokeDashoffset={progressPathLength * (1 + progress)}
            />
          </svg>
          <div className={styles.text}>
            <span>Lvl {level}</span>
            <hr/>
            <span>2 Ref</span>
          </div>
        </div>
        <div className={styles.milestone}>
          <span className="ellipsis">Your level:</span>
          <span className="ellipsis">{usdFormatter.format(currentAmount)} / {usdFormatter.format(nextLevelAmount)}</span>
          <span className="ellipsis"><span>{usdFormatter.format(nextLevelAmount - currentAmount)}</span> to level {level + 1}</span>
        </div>
      </div>
      <div className={styles.stats}>
        <div className={styles.stat}>
          <span>Total invited:</span>
          <span>25 users</span>
        </div>
        <div className={styles.stat}>
          <span>Bonus amount:</span>
          <span>15%</span>
        </div>
      </div>
    </div>
  );
}
