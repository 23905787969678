import React from 'react';
import { useDeviceContext } from 'context/device';

import styles from './content.module.scss';

import Heading from './heading';
import ContainedScrollArea from 'comps/contained-scroll-area';
import Multiplier from './multiplier';
import Chart from './chart';

import bgImg from 'assets/images/crash/content-bg.jpg';
import bg2xImg from 'assets/images/crash/content-bg-2x.jpg';

const Content = React.forwardRef(({
  className,
  ...all
}, ref) => {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <div ref={ref} className={classNames.join(' ')} {...all}>
      <img
        className={`${styles['bg-image']} contained`}
        src={bgImg}
        srcSet={`${bg2xImg} 2x`}
        alt="Wavy space aura with asteroids and stars. Illustration."
      />
      <div className={styles.header}>
        {!isMobile && <Heading className={styles.heading} />}
        <ContainedScrollArea
          className={styles['multipliers-scroll-area']}
          isHorizontal={true}
        >
          <div className={styles.multipliers}>
            {Array(7).fill(null).map((_, i) => {
              return (
                <Multiplier
                  key={i}
                  className={styles.multiplier}
                  amount={i % 2 ? 1.87 : 5.5}
                />
              );
            })}
          </div>
        </ContainedScrollArea>
      </div>
      <Chart className={styles.chart}/>
    </div>
  );
});

export default Content;
