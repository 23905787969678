// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import { ResponsiveLine } from '@nivo/line';

// Sandbox: https://nivo.rocks/line/

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function Line ({
  data,
  xMin = 'auto',
  xMax = 'auto',
  yMin = 'auto',
  yMax = 'auto',
  hideAxes,
}) {
  return (
    <ResponsiveLine
      data={data}
      curve='linear'
      lineWidth={4}
      theme={{
        textColor: '#333333',
        fontSize: 11,
        axis: {
          ticks: {
            line: {
              stroke: 'rgba(198, 170, 170, 0.15)',
              strokeWidth: 2,
            },
            text: {
              fontFamily: 'Inter',
              fontSize: 13,
              fontWeight: 600,
              fill: '#73788F'
            },
          },
        },
      }}
      margin={hideAxes ? {
        top: 3,
        right: 3,
        bottom: 3,
        left: 3,
      } : {
        top: 6,
        right: 12,
        bottom: 45,
        left: 68,
      }}
      xScale={{
        type: 'linear',
        min: xMin,
        max: xMax,
      }}
      yScale={{
          type: 'linear',
          min: yMin,
          max: yMax,
      }}
      enableGridX={false}
      enableGridY={false}
      axisBottom={hideAxes ? null : {
        tickSize: 0,
        tickPadding: 28,
        tickValues: 5,
        format: function(value){ 
          return `${value}s`;
        },
      }}
      axisLeft={hideAxes ? null : {
        tickSize: 25,
        tickPadding: 12,
        tickValues: 6,
        format: function(value){ 
          return `${value}x`;
        },
      }}
      axisRight={hideAxes ? null : {
        tickSize: 2,
        tickValues: 50,
        format: function(value){ 
          return ''; // empty
        },
      }}
      enablePoints={false}
      isInteractive={false}
    />
  );
}
