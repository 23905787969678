import { useDeviceContext } from 'context/device';
import { useRef } from 'react';
import styles from './link-bar.module.scss';

export default function LinkBar({
  className,
  url,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const link = useRef();
  const handleCopy = () => {
    navigator.clipboard.writeText(link.current.textContent);
  };
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <div className={classNames.join(' ')} {...all}>
      <svg className={styles.icon} viewBox="0 0 20 22" width={isMobile ? 19 : 20}>
        <path d="M18.8 17.39a9.5 9.5 0 0 0-5.08-5.08l-.03-.02A6.28 6.28 0 0 0 10 .9a6.3 6.3 0 0 0-3.69 11.4l-.03.02A9.45 9.45 0 0 0 1.2 17.4a9.44 9.44 0 0 0-.73 3.59.2.2 0 0 0 .19.13h1.52a.2.2 0 0 0 .2-.2c.05-1.96.84-3.8 2.23-5.19A7.57 7.57 0 0 1 10 13.49c2.04 0 3.95.79 5.39 2.23a7.57 7.57 0 0 1 2.23 5.19c0 .1.09.2.2.2h1.52a.2.2 0 0 0 .2-.13 9.46 9.46 0 0 0-.74-3.59ZM10 11.56a4.34 4.34 0 0 1-4.37-4.37A4.34 4.34 0 0 1 10 2.82a4.34 4.34 0 0 1 4.37 4.37A4.34 4.34 0 0 1 10 11.56Z" fill="#777A82"/>
      </svg>
      <div className={styles.body}>
        <span>Your link</span>
        <a ref={link} className="ellipsis" href={url} target="_blank">{url}</a>
      </div>
      <button
        className={styles.button}
        onClick={handleCopy}
      >
        <svg viewBox="0 0 24 24" width={isMobile ? 23 : 24}>
          <path d="M18.5 20.13a3.25 3.25 0 0 1-3.25 3.25H3.87a3.25 3.25 0 0 1-3.25-3.25V8.74A3.25 3.25 0 0 1 3.88 5.5v1.63a1.63 1.63 0 0 0-1.62 1.62v11.38a1.62 1.62 0 0 0 1.63 1.62h11.37a1.62 1.62 0 0 0 1.63-1.63h1.62Z"/>
          <path d="M8.75 2.25a1.62 1.62 0 0 0-1.63 1.63v11.37a1.62 1.62 0 0 0 1.63 1.63h11.38a1.62 1.62 0 0 0 1.62-1.63V3.87a1.62 1.62 0 0 0-1.63-1.62H8.76Zm0-1.63h11.38a3.25 3.25 0 0 1 3.25 3.25v11.38a3.25 3.25 0 0 1-3.25 3.25H8.74a3.25 3.25 0 0 1-3.25-3.25V3.87A3.25 3.25 0 0 1 8.75.63Z"/>
        </svg>
      </button>
    </div>
  );
}
