import NiceModal from '@ebay/nice-modal-react';

import TextModal from 'modals/text-modal';

const UserAgreementModal = ({ ...all }) => {
  return (
    <TextModal title="User agreement" {...all}>
      <p>In today's world, Terms of Service pages are not very useful - they're often full of legal jargon that nobody really understands.</p>
      <p>People often perceive CSGO gambling sites as rather suspicious, and we like to think that CSGO stands out clearly from the crowd.</p>
      <p>We understand that you, the player, want to make sure that the site you use is legit when you gamble.</p>
      <p>If you're reading this, that's great. When using CSGO gambling sites, we strongly recommend you do your research before trusting anybody.</p>
      <p>We genuinely want you to be comfortable when using CSGOEmpire. Whether you're gambling with pennies or if you have millions in your balance, we want you to know that you're in good hands.</p>
      <p>For this reason, we want to be as transparent as possible.</p>
      <p>Being fair towards our customers is incredibly important to us, and we want to show the world how fair we really are.</p>
      <p>In all honesty, the main purpose of this page is to limit our legal liabilities. Many of the clauses on this page may not seem very practical. The clauses are probably there simply because our lawyers wanted them. Bureaucracy is wonderful, isn't it?</p>
    </TextModal>
  );
};

export default NiceModal.create(UserAgreementModal);
