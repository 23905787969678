import styles from './content-blocker.module.scss';

export default function ContentBlocker({
  className,
  isVisible,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isVisible) classNames.push(styles.visible);
  return (
    <div className={classNames.join(' ')} {...all}></div>
  );
}
