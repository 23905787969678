import { useDeviceContext } from 'context/device';

import styles from './players.module.scss';

import Player from './player';

export default function Players({
  className,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <div className={classNames.join(' ')} {...all}>
      <div className={styles.header}>
        <span>Players</span>
        <span className={styles.bank}>
          <span>General bank:</span><span>&nbsp;$3,492.88</span>
        </span>
      </div>
      <div className={styles.players}>
        {Array(30).fill(null).map((_, i) => {
          return (
            <Player
              key={i}
              className={styles.player}
              name={i % 2 ? 'Robin' : 'Very long name'}
              amount="$500"
              multiplier="4.50x"
              bank="$800"
            />
          );
        })}
      </div>
    </div>
  );
}
