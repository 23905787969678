import styles from './desktop.module.scss';

import FadeIn from 'comps/fade-in';
import Sidebar from '../sidebar';
import Content from '../content';

export default function Desktop() {
  return (
    <FadeIn className={styles.container}>
      <Sidebar
        className={styles.sidebar}
        isScrollable={true}
      />
      <div className={styles.spacer}></div>
      <Content className={styles.content} />
    </FadeIn>
  );
}
