import NiceModal from '@ebay/nice-modal-react';

import PrivacyPolicyModal from 'modals/privacy-policy-modal';
import UserAgreementModal from 'modals/user-agreement-modal';
import VerifyResultModal from 'modals/verify-result-modal';

export const navLinks = [
  {
    name: 'Leaderboard',
    path: '/leaderboard',
  },
  {
    name: 'Affiliate',
    path: '/affiliate',
  },
  {
    name: 'Privacy policy',
    action: () => NiceModal.show(PrivacyPolicyModal),
  },
  {
    name: 'User agreement',
    action: () => NiceModal.show(UserAgreementModal),
  },
  {
    name: 'Verify results',
    action: () => NiceModal.show(VerifyResultModal),
  },
];

export const games = [
  {
    name: 'Crash',
    path: '/crash',
    icon: {
      Component: ({ width }) => {
        return (
          <svg viewBox="0 0 33 33" width={width}>
            <path d="M32.52.76a.34.34 0 0 0-.26-.26C28.03-.53 18.25 3.15 12.95 8.45a22.9 22.9 0 0 0-2.58 3.04 9.62 9.62 0 0 0-4.66.59C1.8 13.8.64 18.32.32 20.26a.7.7 0 0 0 .77.8l6.3-.69c0 .48.04.95.1 1.42.02.33.17.64.4.87l2.45 2.44c.23.23.53.38.86.41.47.05.95.08 1.42.09l-.7 6.3a.7.7 0 0 0 .82.76c1.93-.3 6.45-1.45 8.17-5.38.61-1.4.73-3.02.6-4.65a22.96 22.96 0 0 0 3.04-2.57c5.32-5.3 8.99-14.85 7.97-19.3ZM19.25 13.75a3.47 3.47 0 1 1 4.9-4.9 3.47 3.47 0 0 1-4.9 4.9Z"/>
            <path d="M10.17 26.87c-.4.4-1.03.55-1.8.68a2.53 2.53 0 0 1-2.92-2.93 4.89 4.89 0 0 1 .77-1.97.32.32 0 0 0-.35-.37c-.96.12-1.86.56-2.54 1.24-1.7 1.7-1.86 8.01-1.86 8.01s6.31-.16 8.01-1.86a4.31 4.31 0 0 0 1.24-2.54c.03-.3-.34-.48-.55-.26Z"/>
          </svg>
        );
      },
      width: {
        mobile: 21,
        desktop: 33,
      },
    },
    available: true,
  },
  {
    name: 'Roulette',
    path: '/roulette',
    icon: {
      Component: ({ width }) => {
        return (
          <svg viewBox="0 0 37 37" width={width}>
            <path d="M8.43 29.66c2.4 2.17 5.5 3.57 8.91 3.83v3.47a18.43 18.43 0 0 1-11.36-4.84l2.45-2.46Zm20.46-.3 2.27 2.28.2.16a18.44 18.44 0 0 1-11.7 5.16V33.5c3.57-.27 6.79-1.8 9.23-4.12ZM18.5 5.79a12.72 12.72 0 1 1 0 25.44 12.72 12.72 0 0 1 0-25.44ZM3.51 19.66a14.96 14.96 0 0 0 3.33 8.33l-2.46 2.46a18.42 18.42 0 0 1-4.34-10.8H3.5Zm33.45 0a18.41 18.41 0 0 1-4.06 10.46 1.15 1.15 0 0 0-.1-.12l-2.37-2.36c1.73-2.24 2.83-5 3.06-7.98h3.47Zm-20.1-7.7a3.47 3.47 0 1 0-.95 5.58l.96.96-.96.96a3.47 3.47 0 1 0 1.63 1.63l.96-.96.96.96a3.47 3.47 0 1 0 1.63-1.63l-.95-.96.95-.96a3.47 3.47 0 1 0-1.63-1.63l-.96.95-.96-.95a3.47 3.47 0 0 0-.68-3.95Zm-3.27 9.81a1.16 1.16 0 1 1 1.64 1.64 1.16 1.16 0 0 1-1.64-1.64Zm8.18 0a1.16 1.16 0 1 1 1.64 1.64 1.16 1.16 0 0 1-1.64-1.64ZM32.41 6.3a18.42 18.42 0 0 1 4.55 11.04H33.5a14.97 14.97 0 0 0-3.54-8.58l2.46-2.46Zm-28.03.25L6.84 9a14.96 14.96 0 0 0-3.33 8.33H.04c.25-4.1 1.84-7.84 4.34-10.8Zm9.21 7.04a1.16 1.16 0 1 1 1.64 1.64 1.16 1.16 0 0 1-1.64-1.64Zm8.18 0a1.16 1.16 0 1 1 1.64 1.64 1.16 1.16 0 0 1-1.64-1.64ZM17.34.04V3.5a14.97 14.97 0 0 0-8.9 3.83L5.98 4.88A18.4 18.4 0 0 1 17.34.04Zm2.32 0C23.92.3 27.79 2 30.78 4.67l-2.46 2.45a14.97 14.97 0 0 0-8.66-3.6V.03Z"/>
          </svg>
        );
      },
      width: {
        mobile: 24,
        desktop: 37,
      },
    },
    available: false,
  },
  {
    name: 'Dice',
    path: '/dice',
    icon: {
      Component: ({ width }) => {
        return (
          <svg viewBox="0 0 29 29" width={width}>
            <path d="M25.3.63H3.7A3.1 3.1 0 0 0 .63 3.7v21.58a3.1 3.1 0 0 0 3.09 3.09H25.3a3.1 3.1 0 0 0 3.09-3.09V3.71a3.1 3.1 0 0 0-3.1-3.08ZM7.55 23.75a2.3 2.3 0 1 1 0-4.62 2.3 2.3 0 0 1 0 4.62Zm0-13.88a2.3 2.3 0 1 1 0-4.61 2.3 2.3 0 0 1 0 4.62Zm6.94 6.94a2.3 2.3 0 1 1 0-4.62 2.3 2.3 0 0 1 0 4.62Zm6.94 6.94a2.3 2.3 0 1 1 0-4.62 2.3 2.3 0 0 1 0 4.62Zm0-13.88a2.3 2.3 0 1 1 0-4.61 2.3 2.3 0 0 1 0 4.62Z"/>
          </svg>
        );
      },
      width: {
        mobile: 18,
        desktop: 29,
      },
    },
    available: false,
  },
  {
    name: 'Mine',
    path: '/mine',
    icon: {
      Component: ({ width }) => {
        return (
          <svg viewBox="0 0 35 35" width={width}>
            <path d="M34.46 19.04v-3.08h-4.73a12.32 12.32 0 0 0-2.5-6.01L30.6 6.6l-2.2-2.2-3.35 3.37a12.31 12.31 0 0 0-6-2.5V.54h-3.1v4.73a12.31 12.31 0 0 0-6 2.5L6.6 4.4 4.4 6.6l3.37 3.35a12.32 12.32 0 0 0-2.5 6H.54v3.1h4.73a12.32 12.32 0 0 0 2.5 6L4.4 28.4l2.2 2.2 3.35-3.37a12.31 12.31 0 0 0 6 2.5v4.73h3.1v-4.73a12.31 12.31 0 0 0 6-2.5l3.35 3.37 2.2-2.2-3.37-3.35a12.32 12.32 0 0 0 2.5-6h4.73Zm-16.96-7.7a6.17 6.17 0 0 0-6.17 6.16H8.25a9.25 9.25 0 0 1 9.25-9.25v3.08Z"/>
          </svg>
        );
      },
      width: {
        mobile: 22,
        desktop: 35,
      },
    },
    available: false,
  },
  {
    name: 'Plinko',
    path: '/plinko',
    icon: {
      Component: ({ width }) => {
        return (
          <svg viewBox="0 0 25 25" width={width}>
            <path d="M12.5 18.67a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08 3.1 3.1 0 0 1-3.08-3.08 3.1 3.1 0 0 1 3.08-3.08Zm0-9.25a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08 3.1 3.1 0 0 1-3.08-3.08 3.1 3.1 0 0 1 3.08-3.08Zm0-9.25a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08 3.1 3.1 0 0 1-3.08-3.08A3.1 3.1 0 0 1 12.5.17Zm-9.25 18.5a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08 3.1 3.1 0 0 1-3.08-3.08 3.1 3.1 0 0 1 3.08-3.08Zm0-9.25a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08A3.1 3.1 0 0 1 .17 12.5a3.1 3.1 0 0 1 3.08-3.08Zm0-9.25a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08A3.1 3.1 0 0 1 .17 3.25 3.1 3.1 0 0 1 3.25.17Zm18.5 18.5a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08 3.1 3.1 0 0 1-3.08-3.08 3.1 3.1 0 0 1 3.08-3.08Zm0-9.25a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08 3.1 3.1 0 0 1-3.08-3.08 3.1 3.1 0 0 1 3.08-3.08Zm0-9.25a3.1 3.1 0 0 1 3.08 3.08 3.1 3.1 0 0 1-3.08 3.08 3.1 3.1 0 0 1-3.08-3.08A3.1 3.1 0 0 1 21.75.17Z"/>
          </svg>
        );
      },
      width: {
        mobile: 16,
        desktop: 25,
      },
    },
    available: false,
  },
  {
    name: 'Tower',
    path: '/tower',
    icon: {
      Component: ({ width }) => {
        return (
          <svg viewBox="0 0 37 37" width={width}>
            <path d="M33.18 3.79 36.48 5c.3.11.52.43.52.76 0 .32-.28.63-.52.73l-3.3 1.22-1.2 3.31a.82.82 0 0 1-.75.54.8.8 0 0 1-.76-.54l-1.21-3.3-3.3-1.23a.8.8 0 0 1-.52-.73c-.07-.33.15-.65.52-.76l3.32-1.21 1.2-3.32a.85.85 0 0 1 .75-.47c.31 0 .62.19.75.47l1.2 3.32Zm-3.8 9.6c.9.91.9 2.37 0 3.27l-.2.21A15.04 15.04 0 1 1 0 21.9C0 13.67 6.73 6.87 15.03 6.87c1.79 0 3.5.38 5.1.95l.2-.2c.9-.9 2.37-.9 3.27 0l5.78 5.78Zm-14.41.48c.7 0 1.15-.52 1.15-1.15 0-.64-.45-1.16-1.15-1.16h-.58a9.87 9.87 0 0 0-9.83 9.83v.58c0 .63.58 1.16 1.16 1.16.7 0 1.15-.53 1.15-1.16v-.58a7.56 7.56 0 0 1 7.52-7.52h.58Z"/>
          </svg>
        );
      },
      width: {
        mobile: 24,
        desktop: 37,
      },
    },
    available: false,
  },
];
