export const roundToDec = (v, d = 0) => {
  const m = Math.pow(10, d);
  return Math.round((v + Number.EPSILON) * m) / m;
};

export const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
};

// Reference: https://github.com/Unity-Technologies/UnityCsReference/blob/02d565cf3dd0f6b15069ba976064c75dc2705b08/Runtime/Export/Math/Mathf.cs#L301-L329
export const smoothDamp = (current, target, currentVel, smoothTime, maxSpeed, deltaTime) => {
  // Based on Game Programming Gems 4 Chapter 1.10
  smoothTime = Math.max(0.0001, smoothTime);
  const omega = 2.0 / smoothTime;

  const x = omega * deltaTime;
  const exp = 1.0 / (1.0 + x + 0.48 * x * x + 0.235 * x * x * x);
  let change = current - target;
  const originalTo = target;

  // Clamp maximum speed
  const maxChange = maxSpeed * smoothTime;
  change = clamp(change, -maxChange, maxChange);
  target = current - change;

  const temp = (currentVel + omega * change) * deltaTime;
  currentVel = (currentVel - omega * temp) * exp;
  let output = target + (change + temp) * exp;

  // Prevent overshooting
  if (originalTo - current > 0.0 == output > originalTo)
  {
      output = originalTo;
      currentVel = (output - originalTo) / deltaTime;
  }

  return [output, currentVel];
};
