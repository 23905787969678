import styles from './chatbar.module.scss';

import * as Separator from '@radix-ui/react-separator';
import ScrollArea from 'comps/scroll-area';
import Message from './message';
import { useEffect, useRef, useState } from 'react';

const initialMesssageDatas = [
  {
    author: 'Jumbo',
    content: 'I hope that today I will win',
  },
  {
    author: 'Dan',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam quam nulla porttitor massa id neque. Nulla facilisi etiam dignissim diam quis. Viverra accumsan in nisl nisi scelerisque. Commodo nulla facilisi nullam vehicula ipsum.',
  },
  {
    author: 'This is an extremely long name',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    author: 'Jumbo',
    content: 'I hope that today I will win',
  },
  {
    author: 'Dan',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam quam nulla porttitor massa id neque. Nulla facilisi etiam dignissim diam quis. Viverra accumsan in nisl nisi scelerisque. Commodo nulla facilisi nullam vehicula ipsum.',
  },
  {
    author: 'This is an extremely long name',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    author: 'Jumbo',
    content: 'I hope that today I will win',
  },
  {
    author: 'Dan',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam quam nulla porttitor massa id neque. Nulla facilisi etiam dignissim diam quis. Viverra accumsan in nisl nisi scelerisque. Commodo nulla facilisi nullam vehicula ipsum.',
  },
  {
    author: 'This is an extremely long name',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

function createMessageData(author, content) {
  return { author, content };
}

export default function Chatbar({
  className,
  ...all
}) {
  const [messageDatas, setMessageDatas] = useState(initialMesssageDatas);
  const [message, setMessage] = useState('');
  const scrollAreaViewport = useRef();
  const handleFormSubmit = e => {
    e.preventDefault();
    const newMessageDatas = messageDatas.slice();
    newMessageDatas.push(createMessageData('Jumbo', message));
    setMessageDatas(newMessageDatas);
    setMessage('');
  };
  useEffect(() => {
    scrollAreaViewport.current.scrollTo({
      top: scrollAreaViewport.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messageDatas]);
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <div className={classNames.join(' ')} {...all}>
      <h3>Chat</h3>
      <Separator.Root className={styles['separator-top']} />
      <ScrollArea
        className={styles['scroll-area']}
        viewportRef={scrollAreaViewport}
      >
        <div className={styles.messages}>
          {messageDatas.map((data, i) => {
            return (
              <Message
                key={i}
                className={styles.message}
                author={data.author}
                content={data.content}
              />
            );
          })}
        </div>
      </ScrollArea>
      <Separator.Root className={styles['separator-bottom']} />
      <form className={styles.form} onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Send a message"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <button type="submit" disabled={!message}>
          <svg viewBox="0 0 25 25" width="25">
            <path d="M1.95.97 24.2 11.94a.72.72 0 0 1 0 1.3L1.94 24.23a.72.72 0 0 1-1.02-.84l2.2-8.33a.72.72 0 0 1 .59-.53l9.98-1.66a.36.36 0 0 0 .27-.21l.03-.1a.36.36 0 0 0-.22-.38l-.08-.03-10.03-1.67a.72.72 0 0 1-.58-.53L.92 1.81A.73.73 0 0 1 1.94.97Z"/>
          </svg>
        </button>
      </form>
    </div>
  );
}
