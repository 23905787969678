import { useDeviceContext } from 'context/device';
import { usdFormatter } from 'utils';

import styles from './sidebar.module.scss';

import ContainedScrollArea from 'comps/contained-scroll-area';
import Image from 'comps/image';
import TextInput from 'comps/text-input';
import CtaButton from 'comps/cta-button';
import Stats from './stats';
import List from './list';

import avatarImg from 'assets/images/avatar.png';
import avatar2xImg from 'assets/images/avatar-2x.png';

export default function Sidebar({
  className,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container, 'gradient-border-box'];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <ContainedScrollArea
      className={classNames.join(' ')}
      isEnabled={!isMobile}
      {...all}
    >
      <div className={styles.inner}>
        <div className={styles.header}>
          <Image
            className={styles['user-image']}
            src={avatarImg}
            srcSet={`${avatar2xImg} 2x`}
            alt="User"
            objectFit="cover"
          />
          <div className={styles['user-info']}>
            <span className="ellipsis">JumboMAX</span>
            <span className="ellipsis">{usdFormatter.format(1000)}</span>
          </div>
        </div>
        <form
          className={styles['promo-form']}
          onSubmit={e => {
            e.preventDefault();
            console.log('Activate promo code');
          }}
        >
          <TextInput placeholder="Promo code" />
          <CtaButton
            type="submit"
          >Activate</CtaButton>
        </form>
        {isMobile && <hr />}
        <h4>Referral system</h4>
        <Stats
          className={styles.stats}
          level={1}
          currentAmount={600}
          nextLevelAmount={1000}
        />
        {isMobile ? <hr /> : <h4>Referral list</h4>}
        <List className={styles.list}/>
      </div>
    </ContainedScrollArea>
  );
}
