import { useDeviceContext } from 'context/device';

import styles from './simple-modal.module.scss';

import Modal from 'modals/modal';

export default function SimpleModal({
  className,
  title,
  children,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <Modal className={classNames.join(' ')} {...all}>
      <div className={styles.header}>
        <span>{title}</span>
      </div>
      {children}
    </Modal>
  );
}
