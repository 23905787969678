import { useDeviceContext } from 'context/device';

import styles from './leaderboard.module.scss';

import FadeIn from 'comps/fade-in';
import ContainedScrollArea from 'comps/contained-scroll-area';
import Entry from './entry';

import avatarImg from 'assets/images/avatar.png';

export default function Leaderboard({
  className,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <FadeIn
      className={classNames.join(' ')}
      {...all}
    >
      <ContainedScrollArea
        className={styles['scroll-area']}
      >
        <table className={styles.table}>
          <thead>
            <tr>
              <th>#</th>
              <th>Wagered</th>
              <th>Prize</th>
              {!isMobile && <th>Medal</th>}
            </tr>
          </thead>
          <tbody>
            {Array(40).fill(null).map((_, i) => {
              const position = i < 3 ? i + 1 : i;
              if (i === 3) {
                // Create a dummy row to make a gap after the podium.
                return <tr key={i}></tr>;
              }
              return (
                <Entry
                  key={i}
                  position={position}
                  imgSrc={avatarImg}
                  name={i % 2 ? 'Bon Jovi' : 'Extremely long name'}
                  wagered={57000 / position}
                  prize={31050 / position}
                />
              );
            })}
          </tbody>
        </table>
      </ContainedScrollArea>
    </FadeIn>
  );
}
