import styles from './menu-toggle.module.scss';

import SvgAnim from './svg-anim';

export default function MenuToggle({
  className,
  isChecked,
  onChange,
  ...all
}) {
  const handleInputChange = e => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <label className={classNames.join(' ')} {...all}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleInputChange}
      />
      <svg viewBox="0 0 38 24" width="38" fill="#D9D9D9" fillRule="evenodd" clipRule="evenodd">
        <rect x="0" y="4.5" width="38" height="3" rx="1" transform="rotate(0 0 0)">
          <SvgAnim
            attributeName="x"
            from="0"
            to="9.2"
            play={isChecked}
          />
          <SvgAnim
            attributeName="y"
            from="4.5"
            to="0"
            play={isChecked}
          />
          <SvgAnim
            attributeName="width"
            from="38"
            to="30.2"
            play={isChecked}
          />
          <SvgAnim
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 0 4.5"
            to="45 9.2 0"
            play={isChecked}
          />
          {/* Reverse */}
          <SvgAnim
            attributeName="x"
            from="9.2"
            to="0"
            play={!isChecked}
          />
          <SvgAnim
            attributeName="y"
            from="0"
            to="4.5"
            play={!isChecked}
          />
          <SvgAnim
            attributeName="width"
            from="30.2"
            to="38"
            play={!isChecked}
          />
          <SvgAnim
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="45 9.2 0"
            to="0 0 4.5"
            play={!isChecked}
          />
        </rect>
        <rect x="0" y="15.5" width="100%" height="3" rx="1" transform="rotate(0 0 0)">
          <SvgAnim
            attributeName="x"
            from="0"
            to="7.6"
            play={isChecked}
          />
          <SvgAnim
            attributeName="y"
            from="15.5"
            to="21.5"
            play={isChecked}
          />
          <SvgAnim
            attributeName="width"
            from="38"
            to="30.2"
            play={isChecked}
          />
          <SvgAnim
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 0 15.5"
            to="-45 7.2 21.5"
            play={isChecked}
          />
          {/* Reverse */}
          <SvgAnim
            attributeName="x"
            from="7.6"
            to="0"
            play={!isChecked}
          />
          <SvgAnim
            attributeName="y"
            from="21.5"
            to="15.5"
            play={!isChecked}
          />
          <SvgAnim
            attributeName="width"
            from="30.2"
            to="38"
            play={!isChecked}
          />
          <SvgAnim
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="-45 7.2 21.5"
            to="0 0 15.5"
            play={!isChecked}
          />
        </rect>
      </svg>
    </label>
  );
}
