import { useState } from 'react';
import { useDeviceContext } from 'context/device';
import { roundToDec } from 'math';

import styles from './sidebar.module.scss';

import ContainedScrollArea from 'comps/contained-scroll-area';
import * as Tabs from '@radix-ui/react-tabs';
import TabList from './tab-list/TabList';
import BetAmount from './bet-amount';
import AutoCashOut from './auto-cash-out';
import CtaButton from 'comps/cta-button';
import Players from './players';

const tabs = [
  {
    name: 'Manual',
    value: 'manual',
  },
  {
    name: 'Auto',
    value: 'auto',
  },
];

export default function Sidebar({
  className,
  isScrollable,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const [betAmount, setBetAmount] = useState(400);
  const [autoCashOut, setAutoCashOut] = useState(55.00);
  const [hasBoughtChest, setBoughtChest] = useState(false);
  const classNames = [styles.container, 'gradient-border-box'];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <ContainedScrollArea
      className={classNames.join(' ')}
      isEnabled={!isMobile}
      {...all}
    >
      <div className={styles.content}>
        <Tabs.Root
          className={styles.betting}
          defaultValue={tabs[0].value}
        >
          {!isMobile && <TabList
            className={styles['tab-list']}
            datas={tabs}
          />}
          <Tabs.Content
            className={styles['tab-content']}
            value="manual"
            tabIndex="-1"
          >
            <BetAmount
              id="crash-page-bet-amount"
              className={styles['bet-amount']}
              hasLabel={!isMobile}
              value={betAmount}
              onChange={e => setBetAmount(e.target.value)}
              onHalf={() => setBetAmount(val => roundToDec(0.5 * val, 2))}
              onDouble={() => setBetAmount(val => 2 * val)}
            />
            {isMobile && <CtaButton
              className={styles['bet-button']}
            >Bet</CtaButton>}
            {!isMobile && <>
              <AutoCashOut
                id="crash-page-auto-cash-out"
                className={styles['auto-cash-out']}
                value={autoCashOut}
                onChange={e => setAutoCashOut(e.target.value)}
              />
              <CtaButton
                className={styles['chest-button']}
                isRed={hasBoughtChest}
                onClick={() => setBoughtChest(val => !val)}
              >{hasBoughtChest ? 'Cash out now' : 'Buy a chest x1'}</CtaButton>
            </>}
          </Tabs.Content>
          <Tabs.Content
            className={styles['tab-content']}
            value="auto"
          >
            <span style={{ marginTop: '38px' }}>Auto betting</span>
          </Tabs.Content>
        </Tabs.Root>
        <Players className={styles.players} />
      </div>
    </ContainedScrollArea>
  );
}
