import styles from './logo.module.scss';

export default function Logo({
  id,
  className,
  ...all
}) {
  const linGradIds = [];
  for (let i = 1; i <= 3; ++i) {
    linGradIds.push(`${id}-lin-grad-${i}`);
  }
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <svg className={classNames.join(' ')} viewBox="0 0 56 43" width="56" fill="none" {...all}>
      <path fillRule="evenodd" clipRule="evenodd" d="m1 6 12.68 33.17 5.28-7.82 6.96-10.57 3.62 3.55L54.74 1 31.58 18.17 25.92 6l-9.35 19.4L9.77 6H1Zm54.18-1.1L42.65 17.3 29.61 29.18l-1.88-1.81-1.96-1.89L15.05 42l10.72-11.01 2.83 2.68L38.3 42 55.19 4.91Z" fill={`url(#${linGradIds[0]})`}/>
      <path d="M42.65 17.3 55.18 4.9 38.31 42l-9.71-8.33-2.83-2.68L15.05 42l10.72-16.52 1.96 1.89 1.88 1.8L42.65 17.3Z" stroke={`url(#${linGradIds[1]})`}/>
      <path d="M13.68 39.17 1 6h8.77l6.8 19.41L25.92 6l5.65 12.17L54.75 1l-25.2 23.33-3.63-3.55-6.96 10.57-5.28 7.82Z" stroke={`url(#${linGradIds[2]})`}/>
      <defs>
        <linearGradient id={linGradIds[0]} x1="25.56" y1="9.54" x2="25.56" y2="42" gradientUnits="userSpaceOnUse">
          <stop stopColor="#49D407"/>
          <stop offset="1" stopColor="#378612"/>
        </linearGradient>
        <linearGradient id={linGradIds[1]} x1="44" y1="40" x2="26" y2="21" gradientUnits="userSpaceOnUse">
          <stop stopColor="#83FF88"/>
          <stop offset="1" stopColor="#83FF88" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id={linGradIds[2]} x1="27.87" y1="1" x2="42.5" y2="18.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#83FF88"/>
          <stop offset="1" stopColor="#83FF88" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
