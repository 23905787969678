import styles from './notification-button.module.scss';

export default function NotificationButton({
  className,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <button className={classNames.join(' ')} {...all}>
      <svg viewBox="0 0 13 15" width="13" fill="#505560">
        <path d="m12.69 11.72-.14-.12a6.1 6.1 0 0 1-1.05-1.22 5.26 5.26 0 0 1-.56-2V6.3A4.5 4.5 0 0 0 7 1.83V1.3a.56.56 0 0 0-1.1 0v.54A4.5 4.5 0 0 0 2 6.31v2.06c-.05.7-.24 1.38-.56 2A6 6 0 0 1 .41 11.6l-.14.12v1.18H12.7v-1.18Zm-7.31 1.61a1.1 1.1 0 0 0 2.2 0h-2.2Z"/>
      </svg>
    </button>
  );
}
