import styles from './close-button.module.scss';

export default function CloseButton({
  className,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <button className={classNames.join(' ')} {...all}>
      <svg viewBox="0 0 16 14" width="16" fill="#fff">
        <path d="m9.64 7 5.02-4.65a1.04 1.04 0 0 0 0-1.54 1.27 1.27 0 0 0-1.66 0L8 5.47 3 .81a1.28 1.28 0 0 0-1.67 0 1.04 1.04 0 0 0 0 1.54L6.35 7l-5.01 4.65a1.02 1.02 0 0 0 0 1.54 1.18 1.18 0 0 0 .83.32 1.24 1.24 0 0 0 .82-.32L8 8.53l5 4.66a1.18 1.18 0 0 0 .83.32 1.24 1.24 0 0 0 .83-.32c.11-.1.2-.22.26-.36a1.02 1.02 0 0 0-.26-1.18L9.64 7Z"/>
      </svg>
    </button>
  );
}
