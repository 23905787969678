import styles from './balance.module.scss';

export default function Balance({
  className,
  onClickAdd,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <div className={classNames.join(' ')} {...all}>
      <span className={styles.value}>$1 000</span>
      <button
        className={styles.button}
        onClick={onClickAdd}
      >
        <svg viewBox="0 0 8 8" width="8" fill="#fff">
          <path fillRule="evenodd" clipRule="evenodd" d="M3 5v3h2V5h3V3H5V0H3v3H0v2h3Z"/>
        </svg>
      </button>
    </div>
  );
}
