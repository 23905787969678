import { useRef } from 'react';

import styles from './mobile.module.scss';

import FadeIn from 'comps/fade-in';
import Sidebar from '../sidebar';
import Content from '../content';

const contentFadeAmount = 0.97;

export default function Mobile() {
  const content = useRef();
  const handleScroll = e => {
    if (contentFadeAmount > 0) {
      const opacity = 1 - Math.min(contentFadeAmount * e.target.scrollTop / content.current.clientHeight, 1);
      content.current.style.opacity = opacity;
    }
  };
  return (
    <FadeIn className={styles.container}>
      <Content
        ref={content}
        className={styles.content}
      />
      <div
        className={`${styles['scroll-viewport']} contained no-scrollbar`}
        onScroll={handleScroll}
      >
        <div className={styles['scroll-content']}>
          <Sidebar className={styles.sidebar} />
        </div>
      </div>
    </FadeIn>
  );
}
