import { useDeviceContext } from 'context/device';

import styles from './player.module.scss';

import Image from 'comps/image';

export default function Player({
  className,
  position,
  imgSrc,
  name,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  const positionClassNames = [styles.position];
  if (position <= 3 && !isMobile) {
    positionClassNames.push(
      styles.podium,
      styles[`pos-${position}`],
      'gradient-border-box');
  }
  return (
    <div className={classNames.join(' ')} {...all}>
      <div className={positionClassNames.join(' ')}>
        <span>{position}</span>
      </div>
      <Image
        className={styles.image}
        src={imgSrc}
        alt={name}
        objectFit="cover"
      />
      <span className={`${styles.name} ellipsis`}>{name}</span>
    </div>
  );
}
