import * as NavMenu from '@radix-ui/react-navigation-menu';

export default function TriggerItem({
  className,
  children,
  ...all
}) {
  return (
    <NavMenu.Item className={className} {...all} asChild>
      <NavMenu.Trigger>
        {children}
      </NavMenu.Trigger>
    </NavMenu.Item>
  );
}
