import styles from './link-item.module.scss';

import * as NavMenu from '@radix-ui/react-navigation-menu';
import { Link } from 'react-router-dom';

export default function LinkItem({
  className,
  isDisabled,
  children,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isDisabled) classNames.push(styles.disabled);
  return (
    <NavMenu.Item className={classNames.join(' ')} asChild {...all}>
      <NavMenu.Link asChild>
        <Link>
          {children}
        </Link>
      </NavMenu.Link>
    </NavMenu.Item>
  );
}
