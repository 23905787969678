import { useRef } from 'react';
import styles from './blocker.module.scss';

export default function Blocker({
  className,
  isVisible,
  onClick,
  children,
  ...all
}) {
  const ref = useRef();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isVisible) classNames.push(styles.visible);
  return (
    <div
      ref={ref}
      className={classNames.join(' ')}
      onClick={e => {
        if (e.target === ref.current && onClick) {
          onClick();
        }
      }}
      {...all}
    >
      {children}
    </div>
  );
}
