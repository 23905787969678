import styles from './message.module.scss';

export default function Message({
  className,
  author,
  content,
  ...all
}) {
  const classNames = [styles.container];
  if (className) classNames.push(className);
  return (
    <div className={classNames.join(' ')} {...all}>
      <div className={styles.header}>
        <span className="ellipsis">{author}</span>
        <span>1 min ago</span>
      </div>
      <p className={`${styles.content} no-scrollbar`}>{content}</p>
    </div>
  );
}
