import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useDeviceContext } from 'context/device';
import { usdFormatter } from 'utils';
import styles from './deposit-modal.module.scss';

import Modal from 'modals/modal';
import CurrencyBar from './currency-bar';
import Icon from './icon';
import AmountForm from './amount-form';
import PromoForm from './promo-form';

const currencies = [
  {
    id: 1,
    name: 'Solana',
    iconSrc: require('assets/images/currency-icons/solana-2x.png'),
  },
  {
    id: 2,
    name: 'USDC',
    iconSrc: require('assets/images/currency-icons/usdc-2x.png'),
  },
  {
    id: 3,
    name: 'Dust',
    iconSrc: require('assets/images/currency-icons/usdc-2x.png'),
  },
  {
    id: 4,
    name: 'Forge',
    iconSrc: require('assets/images/currency-icons/usdc-2x.png'),
  },
  {
    id: 5,
    name: 'Puff',
    iconSrc: require('assets/images/currency-icons/usdc-2x.png'),
  },
];
const minAmount = 1;
const maxAmount = 50000;

const DepositModal = ({
  className,
  ...all
}) => {
  const { isMobile } = useDeviceContext();
  const [currency, setCurrency] = useState(currencies[0].id);
  const [amount, setAmount] = useState(50);
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  const idPrefix = 'deposit-modal';
  return (
    <Modal
      className={classNames.join(' ')}
      {...all}
    >
      <CurrencyBar
        className={styles['currency-bar']}
        datas={currencies}
        value={currency}
        onValueChange={setCurrency}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <Icon
            id={`${idPrefix}-icon`}
            className={styles.icon}
          />
          <span>Deposit</span>
        </div>
        <AmountForm
          id={`${idPrefix}-amount-form`}
          className={styles['amount-form']}
          value={amount}
          minValue={minAmount}
          maxValue={maxAmount}
          hasPresetButtons={!isMobile}
          onValueChange={setAmount}
          onSubmit={e => {
            e.preventDefault();
            console.log('Continue deposit');
          }}
        />
        <PromoForm
          className={styles['promo-form']}
          isInline={!isMobile}
        />
        <div className={styles.extrema}>
          <div className={styles.extremum}>
            <span>Minimum amount: </span>
            <span>{usdFormatter.format(minAmount)}</span>
          </div>
          <div className={styles.extremum}>
            <span>Maximum amount: </span>
            <span>{usdFormatter.format(maxAmount)}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NiceModal.create(DepositModal);
