import { useDeviceContext } from 'context/device';

import styles from './list.module.scss';

import User from './user';

export default function List({
  className,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  return (
    <div className={classNames.join(' ')} {...all}>
      {Array(20).fill(null).map((_, i) => {
        return ( 
          <User
            key={i}
            className={styles.user}
            name={i % 2 ? 'Robin' : 'Very long name'}
            amount={i % 2 ? 800 : 12304.234}
          />
        );
      })}
    </div>
  );
}
