import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from 'layout';
import Crash from 'pages/crash';
import Leaderboard from 'pages/leaderboard';
import Affiliate from 'pages/affiliate';

import './App.css';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Navigate to="/crash" />}/>
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/affiliate" element={<Affiliate />} />
        <Route path="/crash" element={<Crash />} />
        {/*<Route path="*" element={<Navigate to="/" />} />*/}
      </Routes>
    </Layout>
  );
}

export default App;
