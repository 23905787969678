import { useDeviceContext } from 'context/device';

import styles from './bet-amount.module.scss';

import * as Label from '@radix-ui/react-label';
import TextInput from 'comps/text-input';

export default function BetAmount({
  id,
  className,
  hasLabel,
  value,
  onChange,
  onHalf,
  onDouble,
  ...all
}) {
  const { isMobile } = useDeviceContext();
  const classNames = [styles.container];
  if (className) classNames.push(className);
  if (isMobile) classNames.push(styles.mobile);
  const inputId = `${id}-input`;
  return (
    <div className={classNames.join(' ')} {...all}>
      {hasLabel && <Label.Root
        className={styles.label}
        htmlFor={inputId}
      >Bet amount</Label.Root>}
      <div className={styles.field}>
        <TextInput
          className="no-spin"
          type="number"
          id={inputId}
          value={value}
          onChange={onChange}
        />
        <button
          className="gradient-border-box"
          onClick={onHalf}
        >1/2</button>
        <button
          className="gradient-border-box"
          onClick={onDouble}
        >2x</button>
      </div>
    </div>
  );
}
